<template>
  <div class="header-row">
    <div class="sidebar-btn" @click="$emit('openSidebar')"></div>
    <UserInfoHeaderSection :username="currentUser.full_name" />
  </div>
  <div class="header-row">
    <div class="title-section">
      <div class="title-container">
        <div class="title" v-if="activePipeline.projected_value >= 0">
          Pipelines Value
          {{
            '$' +
            activePipeline.projected_value
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }}
        </div>
        <div class="current-date">{{ getPipelineDate() }}</div>
      </div>
    </div>
    <div class="vertical-divider"></div>
    <div
      class="closed-value-container"
      v-if="activePipeline.projected_value >= 0"
    >
      Closed Value
      {{
        '$' +
        activePipeline.closed_value
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }}
    </div>
  </div>
  <div class="header-row">
    <div class="goal-values">
      <PipelineGoalField
        :value="activePipeline.goal_one"
        :placeholder="'Monthly Revenue Goal'"
        :isSubscriptionActive="isSubscriptionActive"
        @pipelineGoalUpdated="handlePipelineGoalsUpdate({ goal_one: $event })"
      />
      <PipelineGoalField
        :value="activePipeline.goal_two"
        :placeholder="'Confidence Level %'"
        :isSubscriptionActive="isSubscriptionActive"
        @pipelineGoalUpdated="handlePipelineGoalsUpdate({ goal_tow: $event })"
      />
      <PipelineGoalField
        :value="activePipeline.goal_three"
        :placeholder="'Non-revenue Goal'"
        :isSubscriptionActive="isSubscriptionActive"
        @pipelineGoalUpdated="handlePipelineGoalsUpdate({ goal_three: $event })"
      />
    </div>
  </div>
  <div class="header-row">
    <ReportsButton
      :width="'500px'"
      :height="'46px'"
      @click="getReportsForActivePipeline"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import UserInfoHeaderSection from '../../../common/header/UserInfoHeaderSection.vue'
import PipelineGoalField from '../../../common/mainContent/PipelineGoalField.vue'
import ReportsButton from '../../../common/ReportsButton.vue'

import { getPipelineDate } from '../../../../utils/helpers'
export default {
  props: ['isSubscriptionActive'],
  components: {
    UserInfoHeaderSection,
    PipelineGoalField,
    ReportsButton,
  },
  data() {
    return {
      errorMessage: '',
      timeStamp: 0,
    }
  },
  computed: {
    ...mapState(['currentUser', 'activePipeline', 'completedSales']),
  },
  methods: {
    ...mapActions(['updatePipelineGoals', 'getMonthReportHtml']),
    getPipelineDate,
    handleGeneralError(error) {
      console.log('==== error while obtaining pipeline info: ', error)
      this.errorMessage = 'Something went wrong, please try again'
    },
    getReportsForActivePipeline() {
      this.getMonthReportHtml(this.activePipeline.id)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styleVars.scss';

.header-row {
  width: 95%;
  margin: 5px;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
  color: $fontColorBlack;

  .vertical-divider {
    width: 1px;
    height: 73px;
    margin-right: 15px;
    background-color: #c9cde8;
  }

  .main-divider-line {
    margin-top: 5px !important;
  }
  .navigation-date-section:deep(.current-closed-date) {
    font-size: 12px !important;
  }
  .sidebar-btn {
    width: 24px;
    height: 24px;
    margin-right: 15px;
    position: relative;
    background-size: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../../../assets/icons/hamburger-icon.svg');
  }

  .goal-values {
    display: flex;
    flex-direction: row !important;
    width: 60%;

    .pipeline-goal-field {
      width: 144px !important;
      margin-left: 5px;
    }
    .pipeline-goal-field:deep(.input-field) {
      width: 120px !important;
    }
  }

  .title-section {
    display: flex;
    justify-content: center;
    align-items: center;

    .title-container {
      display: flex;
      flex-direction: column;

      .title {
        font-size: $fontSizeTitle;
      }

      .current-date {
        font-size: $fontSizeMinified;
        color: $fontColorGray;
      }
    }

    .closed-value-container {
      margin-left: 20px;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
    }
  }
}
</style>
