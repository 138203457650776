<template>
  <div class="pipeline-row">
    <div class="pipeline-row-text-field">
      {{name}}
    </div>
    <div class="pipeline-row-text-field">
      {{company}}
    </div>
    <div class="pipeline-row-text-field">
      {{'$'+dealValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
    </div>
    <div
      v-if="closedDealDate"
      class="pipeline-row-text-field">
      {{ monthConst + '/' + dayConst + '/' + yearConst }}
    </div>
  </div>
</template>

<script>

export default {
  props: [
    'id',
    'name',
    'company',
    'dealValue',
    'closedDealDate',
  ],
  components: {
  },
  data () {
    return {
      dayConst: ("0" + new Date(this.closedDealDate).getUTCDate()).slice(-2),
      monthConst: ("0" +(new Date(this.closedDealDate).getMonth() + 1)).slice(-2),
      yearConst: new Date(this.closedDealDate).getFullYear(),
    }
  }
}
</script>

<style lang="scss">
  @import '../../../../assets/styleVars.scss';

  .pipeline-row {
    width: calc(100% - 80px);
    height: 44px;
    padding: 0 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: $fontSizeStandart;
    color: $fontColorBlack;

    .pipeline-row-text-field {
      width:150px;
      min-width: 150px;
      height: 36px;
      margin-left: 30px;
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: 420px) {
    .card-row {
      width: calc(100% - 20px);
      padding: 0 10px;
    }
  }
</style>
