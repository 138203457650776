<template>
  <MainHeader v-if="windowWidth > 420">
    <div class="header-row">
      <div class="title-section">
        <div class="sidebar-btn" @click="$emit('openSidebar')"></div>
        <div class="title-container">
          <div class="title" v-if="activePipeline.projected_value >= 0">
            Pipeline Value $
            {{
              activePipeline.projected_value
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }}
          </div>
          <div class="current-date">{{ getPipelineDate() }}</div>
        </div>
        <div class="vertical-divider"></div>
        <div
          class="closed-value-container"
          v-if="activePipeline.projected_value >= 0"
        >
          Closed Value $
          {{
            activePipeline.closed_value
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }}
        </div>
      </div>
      <UserInfoHeaderSection :username="currentUser.full_name" />
    </div>
    <div class="header-row">
      <div class="left-side-actions">
        <div class="goal-values">
          <PipelineGoalField
            :value="activePipeline.goal_one"
            :placeholder="'Monthly Revenue Goal'"
            :isSubscriptionActive="isSubscriptionActive"
            @pipelineGoalUpdated="
              handlePipelineGoalsUpdate({ goal_one: $event })
            "
          />
          <PipelineGoalField
            :value="activePipeline.goal_two"
            :placeholder="'Confidence Level %'"
            :isSubscriptionActive="isSubscriptionActive"
            @pipelineGoalUpdated="
              handlePipelineGoalsUpdate({ goal_two: $event })
            "
          />
          <PipelineGoalField
            :value="activePipeline.goal_three"
            :placeholder="'Non-revenue Goal'"
            :isSubscriptionActive="isSubscriptionActive"
            @pipelineGoalUpdated="
              handlePipelineGoalsUpdate({ goal_three: $event })
            "
          />
          <ReportsButton
            :width="'44px'"
            :height="'46px'"
            :class="{ 'inactive-report': !isSubscriptionActive }"
            @click="getReportsForActivePipeline"
          />
        </div>
      </div>
    </div>
  </MainHeader>
  <CompletedSalesMobileHeaderRow
    :isSubscriptionActive="isSubscriptionActive"
    v-if="windowWidth <= 420"
    @openSidebar="$emit('openSidebar')"
  />
  <div class="navigation-date-section">
    <div class="loading-pipeline-nav" v-if="isLoadingPipelineNavigation">
      <div class="spinner"></div>
    </div>
    <div
      v-else-if="pipelineIndex != pipelines.pipelinesCount - 1"
      class="arrow-prev-date-btn"
      @click="getPrevMonthDeals()"
    ></div>
    <div v-else class="arrow-prev-date-not-active"></div>
    <div class="current-closed-date">
      {{ pipelineDate }}
    </div>
    <div
      v-if="pipelineIndex != 0"
      class="arrow-next-date-btn"
      @click="getNextMonthDeals()"
    ></div>
    <div v-else class="arrow-next-date-not-active"></div>
  </div>
  <MainCard>
    <MainCardHeader>
      <div class="card-header-row">
        <div class="card-title">Completed Sales</div>
      </div>
      <CardTableHeader
        :isCheckboxShown="false"
        :isCompanyShown="true"
        :isNameSortArrowHiddent="true"
        :isCompanySortArrowHiddent="true"
        :isValueSortArrowHiddent="true"
        :isValueShown="true"
        :order="order"
        :isClosedDateShown="true"
        :isActionHeaderHiddent="true"
      />
    </MainCardHeader>
    <DividerLine />
    <div
      class="completed-sales-list"
      v-if="currentPage.length && !errorMessage && !isLoading"
    >
      <CompletedSalesCardRow
        v-for="deal in currentPage"
        :key="deal.id"
        :id="deal.id"
        :name="deal.subject_name"
        :company="deal.subject_company"
        :dealValue="deal.value"
        :closedDealDate="deal.completion_date"
      />
    </div>
    <div class="error-message" v-if="errorMessage">
      {{ errorMessage }}
    </div>
    <Spinner v-if="isLoading" />
    <DividerLine />
    <CardFooter
      :currentPageNumber="currentPageNumber"
      :totalPages="toPages"
      :currentItemsCount="`${fromItems}-${toItems}`"
      :totalItemsCount="totalItemsCount"
      :RowsPerPage="RowsPerPage"
      @setCurrentPerPage="setCurrentPerPage($event)"
      @showPrevPage="getPrevPage"
      @showNextPage="getNextPage"
    />
  </MainCard>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import MainHeader from '../../../common/header/MainHeader.vue'
import UndoRedoBtns from '../../../common/header/UndoRedoBtns.vue'
import UserInfoHeaderSection from '../../../common/header/UserInfoHeaderSection.vue'
import MainCard from '../../../common/mainContent/MainCard.vue'
import CardTableHeader from '../../../common/mainContent/CardTableHeader.vue'
import DividerLine from '../../../common/DividerLine.vue'
import SearchField from '../../../common/header/SearchField.vue'
import PipelineGoalField from '../../../common/mainContent/PipelineGoalField.vue'
import MainCardHeader from '../../../common/mainContent/MainCardHeader.vue'
import Spinner from '../../../common/Spinner.vue'
import CardFooter from '../../../common/mainContent/CardFooter.vue'
import CompletedSalesCardRow from './CompletedSalesCardRow.vue'
import CompletedSalesMobileHeaderRow from './CompletedSalesMobileHeaderRow.vue'
import ReportsButton from '../../../common/ReportsButton.vue'

import { getPipelineDate, months } from '../../../../utils/helpers'
import { DealType, UserSubscriptionStatus } from '../../../../utils/constants'

export default {
  components: {
    MainHeader,
    UndoRedoBtns,
    UserInfoHeaderSection,
    MainCard,
    CardTableHeader,
    DividerLine,
    SearchField,
    PipelineGoalField,
    MainCardHeader,
    Spinner,
    CardFooter,
    CompletedSalesCardRow,
    CompletedSalesMobileHeaderRow,
    ReportsButton,
  },
  emits: ['openSidebar'],
  data() {
    return {
      windowWidth: window.innerWidth,
      isLoading: true,
      pageSize: 'All',
      modalError: '',
      selectedRows: [],
      currentPageNumber: 1,
      errorMessage: '',
      order: '',
      dealsType: DealType,
      pipelineMonthArray: months,
      timeStamp: 0,
      pipelineIndex: 0,
      isLoadingPipelineNavigation: false,
      activeSubscriptionStatuses: [
        UserSubscriptionStatus.Granted,
        UserSubscriptionStatus.Active,
        UserSubscriptionStatus['Granted Until'],
        UserSubscriptionStatus.Canceled,
      ],
      RowsPerPage: ['All', 10, 20, 50],
    }
  },
  async created() {
    await this.getInitialData()
  },
  computed: {
    ...mapState([
      'currentUser',
      'activePipeline',
      'completedSales',
      'pipelines',
    ]),
    isSubscriptionActive() {
      return this.activeSubscriptionStatuses.includes(
        this.currentUser.subscription.status
      )
    },
    pipelineDate() {
      const date = new Date(this.timeStamp * 1000)
      const pipelineMonth = this.pipelineMonthArray[date.getUTCMonth()]
      const pipelineYear = date.getFullYear()
      const pipelineFullDate = `${pipelineMonth} ${pipelineYear}`
      return pipelineFullDate
    },
    completedDealsList() {
      return this.completedSales.completedSalesList
    },
    currentPage() {
      if (this.pageSize === 'All') return this.completedDealsList

      const start =
        this.currentPageNumber === 1
          ? 0
          : this.currentPageNumber * this.pageSize - this.pageSize

      const stop =
        this.currentPageNumber === 1
          ? this.pageSize
          : this.currentPageNumber * this.pageSize

      const page = this.completedDealsList.slice(start, stop)

      return page
    },
    totalItemsCount() {
      return this.completedSales.completedSalesCount
    },
    lastPipelinesId() {
      return this.pipelines.pipelinesList.length
        ? this.pipelines.pipelinesList.pop().id
        : 0
    },
    pageItemsBase() {
      return this.pageSize * this.currentPageNumber
    },
    fromItems() {
      if (this.pageSize === 'All') return 1

      return this.totalItemsCount === 0
        ? 0
        : this.pageItemsBase + 1 - this.pageSize
    },
    toPages() {
      if (this.pageSize === 'All') return 1
      if (this.totalItemsCount < this.pageSize) return 1

      return Math.ceil(this.totalItemsCount / this.pageSize)
    },
    toItems() {
      if (this.pageSize === 'All') return this.completedDealsList.length

      return this.pageItemsBase < this.totalItemsCount
        ? this.pageItemsBase
        : this.totalItemsCount
    },
  },
  watch: {
    modalError(newValue, oldValue) {
      if (newValue) {
        setTimeout(() => {
          this.modalError = ''
        }, 3000)
      }
    },
  },
  methods: {
    ...mapActions([
      'getActivePipeline',
      'updatePipelineGoals',
      'getCompletedSalesPage',
      'clearCompletedSales',
      'getPipelinesList',
      'getMonthReportHtml',
      'updatePipelinesList',
    ]),
    getPipelineDate,
    handleGeneralError(error) {
      console.log('==== error while obtaining pipeline info: ', error)
      this.errorMessage = 'Something went wrong, please try again'
    },
    async getInitialData() {
      try {
        await this.getActivePipeline()
        await this.getPipelinesList(this.lastPipelinesId)
        await this.getFirstPage()
      } catch (error) {
        this.handleGeneralError(error)
      } finally {
        this.isLoading = false
      }
    },
    async setCurrentPerPage(rowperPage) {
      this.currentPageNumber = 1
      this.pageSize = rowperPage
      await this.clearCompletedSales()
      await this.getFirstPage()
    },
    getReportsForActivePipeline() {
      if (!this.isSubscriptionActive) return
      this.getMonthReportHtml(this.activePipeline.id)
    },
    async handlePipelineGoalsUpdate(goalUpdate) {
      const goals = {
        goal_one: this.activePipeline.goal_one,
        goal_two: this.activePipeline.goal_two,
        goal_three: this.activePipeline.goal_three,
      }
      const payload = { ...goals, ...goalUpdate }

      await this.updatePipelineGoals(payload)
    },
    async getFirstPage() {
      this.timeStamp = this.activePipeline.timestamp

      const payload = {
        pageSize: this.pageSize,
        pageNumber: this.currentPageNumber,
        pipelineTimeStamp: this.timeStamp,
      }

      await this.getCompletedSalesPage(payload)
    },
    async getCompletedSalesForMonth() {
      this.currentPageNumber = 1
      this.errorMessage = ''

      try {
        const payload = {
          pageSize: this.pageSize,
          pageNumber: this.currentPageNumber,
          pipelineTimeStamp: this.timeStamp,
        }

        await this.clearCompletedSales()
        await this.getCompletedSalesPage(payload)
      } catch (error) {
        console.log(error)
        this.errorMessage = "Pipeline for this month doesn't not exist"
      } finally {
        this.isLoading = false
      }
    },
    async getNextPage() {
      if (
        (this.currentPageNumber !== 0 &&
          this.toItems === this.totalItemsCount) ||
        this.isLoading
      )
        return

      this.isLoading = true
      this.selectedRows = []
      this.currentPageNumber += 1

      try {
        const payload = {
          pageSize: this.pageSize,
          pageNumber: this.currentPageNumber,
          pipelineTimeStamp: this.timeStamp,
        }
        await this.getCompletedSalesPage(payload)
      } catch (error) {
        this.errorMessage = 'Something went wrong...'
      } finally {
        this.isLoading = false
      }
    },
    getPrevPage() {
      if (this.currentPageNumber === 1) return

      this.selectedRows = []
      this.currentPageNumber -= 1
    },
    async getPrevMonthDeals() {
      this.pipelineIndex++
      this.isLoading = true

      let currentMonth = this.pipelines.pipelinesList[this.pipelineIndex]

      try {
        if (!currentMonth) {
          this.isLoadingPipelineNavigation = true
          await this.updatePipelinesList(this.lastPipelinesId)
          currentMonth = this.pipelines.pipelinesList[this.pipelineIndex]
        }

        this.timeStamp = currentMonth.timestamp
        this.isLoading = true

        await this.getCompletedSalesForMonth()
      } catch (error) {
        this.handleGeneralError(error)
      } finally {
        this.isLoadingPipelineNavigation = false
        this.isLoading = false
      }
    },
    async getNextMonthDeals() {
      this.pipelineIndex--
      try {
        this.isLoading = true
        this.timeStamp =
          this.pipelines.pipelinesList[this.pipelineIndex].timestamp
        await this.getCompletedSalesForMonth()
      } catch (error) {
        this.handleGeneralError(error)
      } finally {
        this.isLoading = false
      }
    },
  },
  async beforeUnmount() {
    await this.clearCompletedSales()
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styleVars.scss';
.inactive-report {
  background-image: url('../../../../assets/icons/inactive-report.svg') !important;
}
.header-row {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: $fontColorBlack;

  .sidebar-btn {
    width: 0;
    height: 0;
    position: absolute;
    background-size: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../../../assets/icons/hamburger-icon.svg');
  }

  .title-section {
    display: flex;
    justify-content: center;
    align-items: center;

    .title-container {
      display: flex;
      flex-direction: column;

      .title {
        font-size: $fontSizeTitle;
      }

      .current-date {
        font-size: $fontSizeMinified;
        color: $fontColorGray;
      }
    }

    .vertical-divider {
      width: 1px;
      height: 54px;
      margin-left: 20px;
      top: 35px;
      background: #c9cde8;
    }

    .closed-value-container {
      margin-left: 20px;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
    }
  }

  .left-side-actions {
    position: relative;
    display: flex;

    .goal-values {
      position: relative;
      display: flex;
    }
  }
}

.navigation-date-section {
  text-align: center;
  border: solid 1px $appDeviderLineColor;
  margin-top: 5px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  justify-content: center;
  margin: auto;

  .loading-pipeline-nav {
    width: 20px;
    height: 30px;
    cursor: wait;
    .spinner {
      border: 5px solid $whiteColor;
      border-radius: 50%;
      border-top: 5px solid $appActionColor;
      width: 15px;
      height: 15px;
      display: flex;
      margin-left: auto;
      margin-right: auto;
      animation: spin 2s linear infinite;
    }
  }

  .current-closed-date {
    font-size: $fontSizeModalTitle;
    margin-left: 10%;
    margin-right: 10%;
  }
  .arrow-prev-date-btn {
    background-image: url('../../../../assets/icons/arrow-prev-date.svg');
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .arrow-prev-date-not-active {
    background-image: url('../../../../assets/icons/arrow-prev-date-not-active.svg');
    width: 24px;
    height: 24px;
    cursor: no-drop;
  }
  .arrow-next-date-btn {
    background-image: url('../../../../assets/icons/arrow-next-date.svg');
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .arrow-next-date-not-active {
    background-image: url('../../../../assets/icons/arrow-next-date-not-active.svg');
    width: 24px;
    height: 24px;
    cursor: no-drop;
  }
}

.main-card {
  height: 655px;

  .card-table-header:deep(div:last-of-type) {
    margin-left: 15px;
  }

  .card-header-row {
    width: calc(100% - 135px);
    height: calc(100% - 50px);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .card-title {
      font-size: $fontSizeTitle;
    }
  }
}

@media (max-height: 900px) {
  .main-card {
    height: calc(100% - 100px);
  }

  .navigation-date-section {
    margin-top: 5px;
  }
}

@media (max-width: 1024px) {
  .header-row {
    align-items: center;

    .sidebar-btn {
      position: relative;
      width: 24px;
      height: 24px;
      margin-right: 15px;
    }

    .action-button {
      margin-top: 0;
    }
  }

  .navigation-date-section {
    height: 50px;
  }
}

@media (max-width: 420px) {
  .card-footer {
    width: 980px;
  }
  .card-footer:deep(.rows-per-page) {
    margin-left: 345px !important;
  }
  .completed-sales-list {
    display: flex;
    flex-direction: column;
    overflow-y: visible !important;
    .pipeline-row:deep(.pipeline-row-text-field) {
      min-width: 115px;
    }
  }
  .divider-line {
    width: 1280px;
  }
  .main-divider-line {
    margin-top: 12px;
  }
  .navigation-date-section {
    width: 100%;
    height: 50px;
    .current-closed-date {
      font-size: 17px;
    }
  }

  .card-table-header:deep(.table-header-field) {
    margin-left: 45px;
    min-width: 110px;
  }
  .card-table-header:deep(div:first-of-type) {
    margin-left: 65px;
  }
  .card-table-header {
    width: 100%;
  }
}
</style>

<style lang="scss" scoped>
@import '../../../../assets/styleVars.scss';

.completed-sales-list {
  width: 100%;
  height: calc(100% - 190px);
  overflow-y: scroll;
}

.error-message {
  width: 100%;
  height: calc(100% - 104px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: $redWarningColor;
}
</style>
